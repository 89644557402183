<template>
  <div class="p-text-center p-mt-5">
    <h1>Password Reset</h1>
  </div>

  <div class="p-grid p-mt-4 p-jc-center">
    <div class="p-fluid p-col p-md-3 p-lg-4 p-xl-6">
      <div class="card">

        <h4 class="p-mt-1">Se le enviara un correo con el procedimiento de recuperar contraseña</h4>

        <form @submit.prevent="sendPasswordReset">
          <div class="p-field">
            <div class="p-text-center">
        <span class="p-input-icon-left">
          <i class="pi pi-user"/>
          <InputText ref="emailInput" id="email" type="email" :class="errors.email ? 'p-invalid' : ''"
                     v-model="form.email" placeholder="Ingrese el correo..." required autofocus/>

          <div v-if="errors.email">
            <small id="email-error" class="p-error">{{ this.errors.email }}</small>
          </div>

        </span>
            </div>
          </div>

          <div class="p-mt-3 p-text-center">
            <Button type="submit" label="Submit" :loading="loading"/>
          </div>
        </form>

        <Toast/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
      },
      errors: {
        email: null,
      },
      loading: false,
    }
  },
  methods: {
    async sendPasswordReset() {
      this.loading = true;

      try {
        // const response = await this.$repository.auth.sendPasswordReset(this.form.email)
        await this.$repository.auth.sendPasswordReset(this.form.email)

        this.$toast.add({severity: 'success', summary: 'Correo enviado!', life: 3000});

      } catch (err) {
        const res = err.response;
        this.$toast.add({severity: 'error', summary: res?._error_code ?? 'Error', detail: res?.data.message ?? null, life: 1000});
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>